<template>
    <v-card flat class="rounded-lg" width="70%">
        <v-card-title class="pa-6 text-h5">
            Login
        </v-card-title>
        <v-card-subtitle class="px-6 pt-0 pb-3 mt-n6 text-subtitle-1">
            Please login to your account
        </v-card-subtitle>
        <v-card-text class="pa-6 pt-0">
            <v-form v-model="valid" ref="form">
                <v-text-field
                    v-model="email"
                    :rules="[rules.required, rules.email]"
                    label="Email"
                    required
                />
                <v-text-field
                    v-if="verifyEmailAndPassword || verifyCodeAndPassword"
                    v-model="password"
                    :append-icon="show ? 'mdi-eye-off' : 'mdi-eye'"
                    :rules="[rules.required]"
                    :type="show ? 'text' : 'password'"
                    label="Password"
                    required
                    @click:append="show = !show"
                />
                <div v-if="verifyCodeAndPassword">
                    <span
                        >Enter the 6-digit verification code that was sent to
                        your email address.</span
                    >
                    <div class="d-flex flex-grow">
                        <v-text-field
                            v-model="code"
                            class="mr-2"
                            :rules="[rules.required, rules.code]"
                            label="Code"
                            required
                        />
                        <v-btn
                            text
                            color="primary"
                            class="my-4"
                            @click="verifyEmail"
                            >resend code</v-btn
                        >
                    </div>
                </div>
                <v-card-actions class="pa-6">
                    <v-btn
                        v-if="verifyEmailAndPassword"
                        :loading="loading"
                        block
                        color="primary"
                        depressed
                        :disabled="!valid"
                        @click="signIn"
                        >Login</v-btn
                    >
                    <v-btn
                        v-if="verifyCodeAndPassword"
                        :loading="loading"
                        block
                        color="primary"
                        depressed
                        :disabled="!valid"
                        @click="verifyCode"
                        >Login</v-btn
                    >
                    <v-btn
                        v-if="!verifyEmailAndPassword && !verifyCodeAndPassword"
                        :loading="loading"
                        block
                        color="primary"
                        depressed
                        :disabled="!email"
                        @click="verifyEmail"
                        >SEND</v-btn
                    >
                </v-card-actions>
                <div
                    class="text-center"
                    v-if="verifyEmailAndPassword || verifyCodeAndPassword"
                >
                    <router-link :to="{ name: 'reset password' }">
                        Forgot password
                    </router-link>
                </div>
            </v-form>
        </v-card-text>
        <!--Error Alert-->
        <Errors />
    </v-card>
</template>

<script>
import { auth, messaging, vapidKey } from '../services/firebase'
import API from '../services/api'
import { mapActions, mapMutations } from 'vuex'

export default {
    name: 'SignIn',
    components: {
        Errors: () => import('@/components/Layout/Errors.vue'),
    },
    data() {
        return {
            verifyEmailAndPassword: false,
            verifyCodeAndPassword: false,
            loginState: {},
            loading: false,
            valid: false,
            error: false,
            errorMsg: null,
            rules: {
                required: v => !!v || 'Required',
                email: v => /.+@.+\..+/.test(v) || 'Not a valid email',
                code: v =>
                    (/^[A-Za-z0-9]+$/g.test(v) && v.length == 6) ||
                    'Not a valid code',
            },
            email: '',
            password: '',
            code: '',
            show: false,
            currentToken: undefined,
        }
    },
    mounted() {
        Notification.requestPermission().then(permission => {
            if (permission === 'granted' && messaging) {
                messaging
                    .getToken({ vapidKey })
                    .then(currentToken => {
                        this.currentToken = currentToken
                    })
                    .catch(err => {
                        this.error = true
                        this.errorMsg = err.message
                    })
            }
        })
    },
    methods: {
        ...mapActions(['actionSaveUserRef']),
        ...mapMutations(['setErrorItems']),
        async verifyCode() {
            try {
                this.loading = true
                const user = await API.verifyCode(this.email, this.code)
                if (user.authorized) {
                    await this.signIn()
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async verifyEmail() {
            try {
                this.loading = true
                const user = await API.verifyEmail(this.email)
                if (user.operator) {
                    this.verifyEmailAndPassword = true
                } else {
                    this.verifyCodeAndPassword = true
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        signIn: async function() {
            try {
                this.loading = true
                await auth().signInWithEmailAndPassword(
                    this.email,
                    this.password
                )
                //
                const {
                    data: { user },
                } = await API.getMyInfo()
                this.actionSaveUserRef(user)
                localStorage.setItem('userId', JSON.stringify(user.id))
                localStorage.setItem('user', JSON.stringify(user))
                localStorage.setItem('company', JSON.stringify(user.companyId))
                let path = user.permissions.includes('readTasks')
                    ? 'tasks'
                    : 'main-board'
                path = user.operatorRole ? 'operator' : path
                this.$router.push({ path }).catch(error => error && null)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
                try {
                    // subscribe to topic for notifications
                    if (this.currentToken && messaging)
                        await API.subscribeToTopic(this.currentToken)
                } catch (error) {
                    console.log(error.message)
                }
            }
        },
    },
}
</script>
